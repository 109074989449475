import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import ActionTypes from './actions';

export interface LoginUserProfileState {
  profile: any | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: LoginUserProfileState = {
  profile: null,
  status: 'idle',
  error: null,
};

const loginUserProfileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      ActionTypes.FETCH_LOGIN_USER_PROFILE_FROM_SESSION_REQUEST,
      (state) => {
        state.status = 'loading';
      },
    )
    .addCase(ActionTypes.FETCH_LOGIN_USER_PROFILE_REQUEST, (state) => {
      state.status = 'loading';
    })
    .addCase(
      ActionTypes.FETCH_LOGIN_USER_PROFILE_SUCCESS,
      (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.profile = action.payload;
      },
    )
    .addCase(
      ActionTypes.FETCH_LOGIN_USER_PROFILE_FAILURE,
      (state, action: PayloadAction<string>) => {
        state.status = 'failed';
        state.error = action.payload;
      },
    );
});

export default loginUserProfileReducer;
