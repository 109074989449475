import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AppsIcon from '@mui/icons-material/Apps';

interface NavigationItem {
  id: string;
  icon: React.ReactNode;
  label: string;
  path: string;
}

const NavigationItems: NavigationItem[] = [
  { id: '1', icon: <DashboardOutlinedIcon />, label: 'Dashboard', path: '/' },
  { id: '2', icon: <WorkOutlineOutlinedIcon />, label: 'Jobs', path: '/jobs' },
  {
    id: '3',
    icon: <CorporateFareOutlinedIcon />,
    label: 'Companies',
    path: '/companies',
  },
  { id: '4', icon: <Groups2OutlinedIcon />, label: 'Users', path: '/users' },
  { id: '5', icon: <BadgeOutlinedIcon />, label: 'HR', path: '/hr' },
  { id: '6', icon: <AppsIcon />, label: 'Applicants', path: '/job-applicants' },
];

export default NavigationItems;
