// GraphQL Queries and Mutations for Job Applications, Companies, and Job Seekers

// ------------------- Job Applications Queries -------------------
export const jobApplicantsList = (page: number, size: number) => `
  query {
    getAllJobApplications(page: ${page}, size: ${size}, sort: "createdAt", sortDirection: "asc") {
      content {
        _id,
        status,
        jobSeeker {
          personalDetails {
            firstName,
            lastName
          }
        },
        job {
          title,
          company {
            companyName
          }
        }
      }
      totalPages,
      totalElements
    }
  }
`;

export const jobApplicationTracking = (id: string) => `
  query {
    getJobApplicationById(_id: "${id}") {
      _id,
      status,
      history {
        status,
        date,
        changedBy
      }
      notes {
        note,
        changedBy,
        date
      }
    }
  }
`;

export const jobApplicationStatusUpdate = (id: string, status: string) => `
  mutation {
    updateJobApplicationById(jobApplication: {
      _id: "${id}",
      status: "${status}",
      updatedBy: "Khal"
    }) {
      _id,
      status,
      history {
        status,
        date,
        changedBy
      }
      notes {
        note,
        changedBy,
        date
      }
    }
  }
`;

export const jobApplicationNoteSave = (id: string, note: string) => `
  mutation {
    updateJobApplicationById(jobApplication: {
      _id: "${id}",
      note: "${note}",
      updatedAt: "2024-08-31T19:06:00Z",
      updatedBy: "Khal"
    }) {
      _id,
      status,
      history {
        status,
        date,
        changedBy
      }
      notes {
        note,
        changedBy,
        date
      }
    }
  }
`;

// ------------------- Company Queries -------------------
export const companyById = (companyId: string) => `
  query {
    getCompanyById(_id: "${companyId}") {
      _id,
      companyName,
      companyDetails {
        country,
        city,
        zipCode
      },
      about,
      status,
      history {
        date,
        status,
        changedBy
      }
    }
  }
`;

export const companyList = (page: number, size: number) => `
  query {
    getAllCompanies(page: ${page}, size: ${size}) {
      totalPages,
      totalElements,
      content {
        _id,
        companyName,
        companyDetails {
          country,
          city,
          zipCode
        },
        status,
        history {
          date,
          status,
          changedBy
        }
      }
    }
  }
`;

export const companyUpdate = (companyId: string, companyData: any): string => `
  mutation {
    updateCompanyById(_id: "${companyId}", company: {
      companyName: "${companyData.companyName}",
      companyEmail: "${companyData.companyEmail}",
      companyPhone: "${companyData.companyPhone}",
      website: "${companyData.website}",
      facebookURL: "${companyData.facebookURL}",
      instagramURL: "${companyData.instagramURL}",
      linkedInURL: "${companyData.linkedInURL}",
      about: "${companyData.about}",
      companyDetails: {
        country: "${companyData.companyDetails.country}",
        city: "${companyData.companyDetails.city}",
        zipCode: "${companyData.companyDetails.zipCode}"
      },
      logo: ${companyData.logo ? `"${companyData.logo}"` : 'null'},
      status: "${companyData.status}"
    }) {
      _id,
      companyName
    }
  }
`;

export const createCompanyQuery = (data: any) => `
  mutation {
    createCompany(
      company: {
        companyName: "${data.companyName}",
        createdAt: "${data.createdAt}",
        createdBy: "${data.createdBy}",
        employers: ${JSON.stringify(data.employers)},
        jobsPosted: ${JSON.stringify(data.jobsPosted)},
        companyDetails: {
          address: "${data.companyDetails.address}",
          city: "${data.companyDetails.city}",
          state: "${data.companyDetails.state}",
          country: "${data.companyDetails.country}",
          zipCode: "${data.companyDetails.zipCode}"
        }
      }
    ) {
      _id
    }
  }
`;

export const createUpdateStatusQuery = (id: string, status: string) => `
  mutation {
    updateCompanyById(
      _id: "${id}",
      company: { status: "${status}", updatedBy: "Dev" }
    ) {
      _id
    }
  }
`;

// ------------------- Job Queries -------------------
export const jobList = (page: number, size: number) => `
  query {
    getAllJobs(page: ${page}, size: ${size}) {
      content {
        _id,
        postedDate,
        title,
        company {
          companyName
        },
        salary
      }
      totalElements,
      totalPages
    }
  }
`;

// ------------------- User Queries -------------------
export const jobSeeker = (page: number, size: number) => `
  query {
    getAllJobSeekers(page: ${page}, size: ${size}) {
      content {
        _id,
        personalDetails {
          fullName,
          city,
          state,
          country,
          email
        }
      }
      totalElements,
      totalPages
    }
  }
`;

export const getUserById = (id: string) => `
  query {
    getJobSeekerById(_id: "${id}") {
      _id,
      createdAt,
      createdBy,
      emailLoginDetails {
        password
      },
      jobPreferences {
        jobType,
        location,
        noticePeriod,
        preferredRoles
      },
      personalDetails {
        address,
        city,
        country,
        email,
        firstName,
        lastName,
        phone,
        state,
        zipCode
      },
      resume
    }
  }
`;

export const updateJobSeeker = (data: UserFormData) => `
  mutation {
    updateJobSeekerById(
      _id: "${data.id}", 
      jobSeeker: {
        updatedBy: "Khal",
        jobPreferences: { 
          jobType: "${data.jobType || ''}", 
          location: "${data.location || ''}", 
          noticePeriod: "${data.noticePeriod || ''}", 
          preferredRoles: "${data.preferredRoles || ''}" 
        },
        personalDetails: {
          address: "${data.address || ''}",
          city: "${data.city || ''}",
          country: "${data.country || ''}",
          email: "${data.email || ''}",
          firstName: "${data.firstName || ''}",
          lastName: "${data.lastName || ''}",
          state: "${data.state || ''}",
          phone: "${data.phone || ''}",
          zipCode: "${data.zipcode || ''}"
        },
        resume: "${data.resume || ''}"
      }
    ) {
      _id
    }
  }
`;
