import { Box, CircularProgress } from '@mui/material';
import './index.scss';

export default function Loader() {
  return (
    <Box className="loading-container">
      <CircularProgress />
    </Box>
  );
}
