import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import './index.scss';
import PageHeader from '@components/Common/PageHeader';
import PageSearch from '@components/Common/PageSearch';
import PageLayout from '@components/Common/PageLayout';
import Popup from '@components/Common/Popup';
import CompanyDetailsPopup from '@components/CompanyDetailsPopup';
import MintlyTable from '@components/Common/Table';
import { companyList } from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';

const rowsPerPage = 10;

export interface CompanyList {
  id: string;
  address: {
    country: string;
    city: string;
    zipCode: string;
  };
  companyName: string;
  approvalStatus: string;
}

const Companies = () => {
  const [page, setPage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CompanyList[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const fetchData = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const query = companyList(page, rowsPerPage);
      const { data } = await fetchGraphQLApiData(query);
      const { getAllCompanies } = data;

      if (getAllCompanies.content && getAllCompanies.totalElements) {
        const companies: CompanyList[] = getAllCompanies.content.map(
          (item: any) => ({
            id: item._id,
            address: {
              country: item.companyDetails?.country,
              city: item.companyDetails?.city ?? '',
              zipCode: item.companyDetails?.zipCode ?? '',
            },
            companyName: item.companyName,
            approvalStatus: item.status ?? '',
          }),
        );

        setData(companies);
        setTotalElements(getAllCompanies.totalElements);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('An unknown error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!popupOpen) {
      fetchData(page);
    }
  }, [fetchData, page]);

  const renderContent = () => {
    if (loading) {
      return (
        <Loader />
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    return (
        <MintlyTable
          rows={data}
          pagination={{
            enabled: true,
            page: page,
            rowsPerPage,
            totalNumberOfRows: totalElements,
            handleChangePage: handleChangePage,
          }}
          selectors={[            
            'companyName',
            'approvalStatus',
            'location',
            'expand',
          ]}
          tableHeadRow={{            
            companyName: <>Company Name</>,
            approvalStatus: <>Approval Status</>,
            location: <>Location</>,
            expand: <></>,
          }}
          tableBodyRow={{
            companyName: (row: any) => <>{row.companyName}</>,
            approvalStatus: (row: any) => <>{row.approvalStatus}</>,
            location: (row: any) => (
              <>{`${row.address.city}, ${row.address.country}, ${row.address.zipCode}`}</>
            ),
            expand: (row: any) => (
              <IconButton
                className="view-button"
                onClick={() => {
                  console.log(row, 'check query');
                  setSelectedCompanyId(row.id);
                  setPopupOpen(true);
                }}
              >
                View
              </IconButton>
            ),            
          }}
        />
        
    );
  };

  return (
    <PageLayout className="companies-container">
      <PageHeader title="Companies" />
      <PageSearch placeholder="Search Companies..." />
      {renderContent()}
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
        <CompanyDetailsPopup companyId={selectedCompanyId} onClose={() => setPopupOpen(false)} />
      </Popup>
    </PageLayout>
  );
};

export default Companies;
