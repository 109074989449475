import './index.scss';
import React from 'react';
import { Box, Typography } from '@mui/material';

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Box className="error-message-container">
      <Typography variant="body1" component="p">
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
