import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import './index.scss';
import PageHeader from '@components/Common/PageHeader';
import PageSearch from '@components/Common/PageSearch';
import PageLayout from '@components/Common/PageLayout';
import Popup from '@components/Common/Popup';
import MintlyTable from '@components/Common/Table';
import { Edit, Download } from '@mui/icons-material';
import { jobSeeker } from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import UserDetailsPopup from '@components/UserDetailsPopup';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';

const rowsPerPage = 10;

interface JobSeekerData {
  id: string;
  name: string;
  email: string;
  city: string;
  state: string;
  country: string;
  resume: any;
}

const Users = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [data, setData] = useState<JobSeekerData[]>([]);
  const [totalElements, setTotalElements] = useState(0);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const fetchData = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const query = jobSeeker(page, rowsPerPage);
      const { data } = await fetchGraphQLApiData(query);
      const { getAllJobSeekers } = data;

      if (getAllJobSeekers.content && getAllJobSeekers.totalElements) {
        const jobApplicants: JobSeekerData[] = getAllJobSeekers.content.map(
          (item: any) => ({
            id: item._id,
            name: item.personalDetails.fullName,
            email: item.personalDetails.email,
            city: item.personalDetails.city,
            state: item.personalDetails.state,
            country: item.personalDetails.country,
            resume: '',
          }),
        );
        setData(jobApplicants);
        setTotalElements(getAllJobSeekers.totalElements);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('An unknown error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [fetchData, page]);

  const renderContent = () => {
    if (loading) {
      return (
        <Loader />
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    return (
      <MintlyTable
        rows={data}
        pagination={{
          enabled: true,
          page: page,
          rowsPerPage,
          totalNumberOfRows: totalElements,
          handleChangePage: handleChangePage,
        }}
        selectors={[        
          'name',
          'email',
          'city',
          'state',
          'country',
          'resume',
          'expand',
        ]}
        tableHeadRow={{
          name: <>Name</>,
          email: <>Email</>,
          city: <>City</>,
          state: <>State</>,
          country: <>Country</>,
          resume: <></>,
          expand: <></>,
        }}
        tableBodyRow={{
          name: (row: any) => <>{row.name}</>,
          email: (row: any) => <>{row.email}</>,
          city: (row: any) => <>{row.city}</>,
          state: (row: any) => <>{row.state}</>,
          country: (row: any) => <>{row.country}</>,
          resume: (row: any) => (
            <IconButton
              component="a"
              href={`/${row.resume}`}
              download
              title="Download Resume"
            >
              <Download />
            </IconButton>
          ),
          expand: (row: any) => (
            <IconButton              
              onClick={() => {
                setSelectedUserId(row.id);
                setPopupOpen(true);
              }}
            >
              <Edit />
            </IconButton>
          ),
        }}
      />
    );
  };

  return (
    <PageLayout className="users-container">
      <PageHeader title="Users" />
      <PageSearch placeholder="Search Users..." />
      {renderContent()}    
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
        <UserDetailsPopup userId={selectedUserId} onClose={() => setPopupOpen(false)} />
      </Popup>
    </PageLayout>
  );
};

export default Users;
