import { useEffect, useCallback, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
} from '@mui/material';
import PopupLayout from '@components/Common/PopupLayout';
import './index.scss';
import PopupHeader from '@components/Common/PopupHeader';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';
import { getUserById, updateJobSeeker } from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';

export interface UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  jobType: string;
  location: string;
  noticePeriod: string;
  preferredRoles: string;
  id: string;
  resume: string;
}

interface UserDetailsPopupProps {
  userId: string;
  onClose: () => void;
}

const UserDetailsPopup: React.FC<UserDetailsPopupProps> = ({ userId, onClose }) => {
  const [user, setUser] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<UserFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    jobType: '',
    location: '',
    noticePeriod: '',
    preferredRoles: '',
    id: userId,
    resume: '',
  });

  const fetchData = useCallback(async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      const query = getUserById(id);
      const { data } = await fetchGraphQLApiData(query);
      setUser(data.getJobSeekerById);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(userId);
  }, [userId, fetchData]);

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.personalDetails.firstName || '',
        lastName: user.personalDetails.lastName || '',
        email: user.personalDetails.email || '',
        phone: user.personalDetails.phone || '',
        address: user.personalDetails.address || '',
        city: user.personalDetails.city || '',
        state: user.personalDetails.state || '',
        zipcode: user.personalDetails.zipCode || '',
        country: user.personalDetails.country || '',
        jobType: user.jobPreferences.jobType.join(', ') || '',
        location: user.jobPreferences.location || '',
        noticePeriod: user.jobPreferences.noticePeriod || '',
        preferredRoles: user.jobPreferences.preferredRoles.join(', ') || '',
        id: userId,
        resume: user.resume || '',
      });
    }
  }, [user, userId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const query = updateJobSeeker(formData);
    
    try {
      await fetchGraphQLApiData(query);      
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopupLayout className="user-details-popup-layout-container">
      <Container maxWidth="xl" className="user-details-container">
        <PopupHeader title="User Details" onClose={onClose} />

        {loading && <Loader />}
        {error && <ErrorMessage message={error} />}

        {user && (
          <Grid container spacing={2}>
            {[
              { label: 'First Name', name: 'firstName', xs: 6, required: true },
              { label: 'Last Name', name: 'lastName', xs: 6, required: true },
              { label: 'Email', name: 'email', xs: 12, type: 'email', required: true },
              { label: 'Phone', name: 'phone', xs: 12, required: true },
              { label: 'Address', name: 'address', xs: 12, required: true },
              { label: 'City', name: 'city', xs: 6, required: true },
              { label: 'State', name: 'state', xs: 6, required: true },
              { label: 'Zipcode', name: 'zipcode', xs: 6, required: true },
              { label: 'Country', name: 'country', xs: 6, required: true },
              { label: 'Job Type', name: 'jobType', xs: 12, required: true },
              { label: 'Preferred Location', name: 'location', xs: 12, required: true },
              { label: 'Notice Period', name: 'noticePeriod', xs: 6, required: true },
              { label: 'Preferred Roles', name: 'preferredRoles', xs: 6, required: true },
            ].map((field) => (
              <Grid item xs={field.xs} key={field.name} className="form-control">
                <TextField
                  fullWidth
                  label={field.label}
                  name={field.name}
                  type={field.type || 'text'}
                  value={formData[field.name as keyof UserFormData]}
                  onChange={handleChange}
                  required={field.required}
                  className="text-field"
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                className="save-button"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </PopupLayout>
  );
};

export default UserDetailsPopup;
