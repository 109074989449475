import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { fetchLoginUserProfileFromSessionRequest } from '@store/features/loginUserProfile/actions';
import Loader from '@components/Common/Loader';
import LayoutPresentation from './LayoutPresentation.tsx';

const mapStateToProps = (state: RootState) => ({
  loginUserProfile: state.loginUserProfile,
});

const mapDispatchToProps = {
  fetchLoginUserProfileFromSessionRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const LayoutContainer: React.FC<PropsFromRedux> = ({
  loginUserProfile,
  fetchLoginUserProfileFromSessionRequest,
}) => {
  useEffect(() => {
    fetchLoginUserProfileFromSessionRequest();
  }, []);

  if (!loginUserProfile) {
    return <Loader />;
  }

  return <LayoutPresentation loginUserProfile={loginUserProfile} />;
};

export default connector(LayoutContainer);
