import { ActionTypes } from './actions';

interface JobApplicant {
  id: string;
  name: string;
  designation: string;
  companyName: string;
  status: string;
}

export interface JobApplicantState {
  data: JobApplicant[];
  totalElements: number;
  loading: boolean;
  error: string | null;
}

const initialState: JobApplicantState = {
  data: [],
  totalElements: 0,
  loading: false,
  error: null,
};

const jobApplicantReducer = (
  state = initialState,
  action: any,
): JobApplicantState => {
  switch (action.type) {
    case ActionTypes.FETCH_JOB_APPLICANTS_REQUEST:
      return { ...state, loading: true, error: null };
    case ActionTypes.FETCH_JOB_APPLICANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalElements: action.payload.totalElements,
      };
    case ActionTypes.FETCH_JOB_APPLICANTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default jobApplicantReducer;
