import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import { jobApplicantsList } from '@constants/graphQLQueries';
import {
  ActionTypes,
  fetchJobApplicantsFailure,
  fetchJobApplicantsSuccess,
} from './actions';

function* fetchJobApplicantsSaga(action: any) {
  try {
    const { page, rowsPerPage } = action.payload;
    const query = jobApplicantsList(page, rowsPerPage);
    const response = yield call(fetchGraphQLApiData, query);

    if (response.content && response.totalElements) {
      const jobApplicants = response.content.map((item: any) => ({
        id: item._id,
        name: `${item.jobSeeker.personalDetails.firstName} ${item.jobSeeker.personalDetails.lastName}`,
        designation: item.job.title,
        companyName: item.job.company.companyName,
        status: item.status,
      }));

      yield put(
        fetchJobApplicantsSuccess(jobApplicants, response.totalElements),
      );
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('An unknown error occurred while fetching data:', error);
    yield put(
      fetchJobApplicantsFailure(
        'Failed to fetch data. Please try again later.',
      ),
    );
  }
}

export function* jobApplicantsSaga() {
  yield takeLatest(
    ActionTypes.FETCH_JOB_APPLICANTS_REQUEST,
    fetchJobApplicantsSaga,
  );
}
