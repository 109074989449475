import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '@store/rootReducer';
import { fetchLoginUserProfileRequest } from '@store/features/loginUserProfile/actions';
import AuthenticationFormPresentation from './AuthenticationFormPresentation.tsx'; // Import the presentational component

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLoginUserProfileRequest: (payload: any) =>
    dispatch(fetchLoginUserProfileRequest(payload)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AuthenticationContainer: React.FC<PropsFromRedux> = ({
  fetchLoginUserProfileRequest,
}) => {
  const handleSubmit = (email: string, password: string) => {
    fetchLoginUserProfileRequest({ email, password });
  };

  return <AuthenticationFormPresentation onSubmit={handleSubmit} />;
};

export default connector(AuthenticationContainer);
