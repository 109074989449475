import IconButton from '@mui/material/IconButton';
import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import MintlyTable from '@components/Common/Table';
import PageHeader from '@components/Common/PageHeader';
import PageSearch from '@components/Common/PageSearch';
import PageLayout from '@components/Common/PageLayout';
import Popup from '@components/Common/Popup';
import { jobList } from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';
import JobDetailsPopup from '@components/JobDetailsPopup';

const rowsPerPage = 10;

interface JobData {
  id: string;
  jobName: string;
  datePosted: string;
  relevantCandidates: number;
  industry: string;
}

const Jobs = () => {
  const [page, setPage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [data, setData] = useState<JobData[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChangePage = (
    _event: React.MouseEvent<Element, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const fetchData = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const query = jobList(page, rowsPerPage);
      const { data } = await fetchGraphQLApiData(query);
      const { getAllJobs } = data;

      if (getAllJobs.content && getAllJobs.totalElements) {
        const jobApplicants: JobData[] = getAllJobs.content.map(
          (item: any) => ({
            id: item._id,
            industry: item.company.companyName,
            releventCandidates: Math.round(Math.random() * 10),
            datePosted: item.postedDate ?? '12/12/24',
            jobName: item.title,
          }),
        );

        setData(jobApplicants);
        setTotalElements(getAllJobs.totalElements);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('An unknown error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [fetchData, page]);

  const renderContent = () => {
    if (loading) {
      return (
        <Loader />
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    return (
      <MintlyTable
        rows={data}
        pagination={{
          enabled: true,
          page: page,
          rowsPerPage,
          totalNumberOfRows: totalElements,
          handleChangePage,
        }}
        selectors={[          
          'jobName',
          'datePosted',
          'relevantCandidates',
          'industry',
          'expand',
        ]}
        tableHeadRow={{          
          jobName: <>Job Name</>,
          datePosted: <>Date Posted</>,
          relevantCandidates: <>Relevant Candidates</>,
          industry: <>Org Name</>,
          expand: <></>,
        }}
        tableBodyRow={{          
          jobName: (row: any) => <>{row.jobName}</>,
          datePosted: (row: any) => <>{row.datePosted}</>,
          relevantCandidates: (row: any) => <>{row.releventCandidates}</>,
          industry: (row: any) => <>{row.industry}</>,
          expand: (row: any) => (
            <IconButton
              className="view-button"
              onClick={() => {
                setSelectedJobId(row.id)
                setPopupOpen(true);
              }}
            >
              View
            </IconButton>
          ),
        }}
      />
    );
  };

  return (
    <PageLayout className="jobs-container">
      <PageHeader title="Jobs" />
      <PageSearch placeholder="Search Jobs..." />
      {renderContent()}
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
        <JobDetailsPopup jobId={selectedJobId} onClose={() => setPopupOpen(false)} />
      </Popup>
    </PageLayout>
  );
};

export default Jobs;
