import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import './index.scss';

interface PaginationProps {
  enabled: boolean;
  page: number;
  rowsPerPage: number;
  totalNumberOfRows: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
}

interface TableProps {
  rows: any[];
  selectors: string[];
  tableHeadRow: Record<string, React.ReactNode>;
  tableBodyRow: Record<string, (row: any, index: number) => React.ReactNode>;
  pagination: PaginationProps;
}

const defaultPagination: PaginationProps = {
  enabled: false,
  page: 0,
  rowsPerPage: 10,
  totalNumberOfRows: 0,
  handleChangePage: () => {},
};

const MintlyTable: React.FC<TableProps> = ({
  rows = [],
  selectors = [],
  tableHeadRow = {},
  tableBodyRow = {},
  pagination = defaultPagination,
}) => {
  return (
    <Box className="table-container">
      <Box className="card-view">
        {rows.map((row, index) => (
          <Box className="card" key={index}>
            {selectors.map((selector) => (
              <Box key={selector} className="card-item">
                <Typography variant="body2" className="card-label">
                  {tableHeadRow[selector]}
                </Typography>
                <Typography variant="body1" className="card-value">
                  {tableBodyRow[selector](row, index)}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      <TableContainer className="table-container-desktop">
        <Table aria-label="data table">
          <TableHead className="table-head">
            <TableRow className="table-row">
              {selectors.map((selector) => (
                <TableCell className="table-cell" key={selector}>
                  {tableHeadRow[selector]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {rows.map((row, index) => (
              <TableRow className="table-row" key={index}>
                {selectors.map((selector) => (
                  <TableCell className="table-cell" key={selector}>
                    {tableBodyRow[selector](row, index)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination.enabled && (
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[]}
          component="div"
          count={pagination.totalNumberOfRows}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={pagination.handleChangePage}
        />
      )}
    </Box>
  );
};

export default React.memo(MintlyTable);
