import { all } from 'redux-saga/effects';
import {
  loginUserFromSessionSaga,
  loginUserProfileSaga,
} from '@store/features/loginUserProfile/sagas';
import { jobApplicantsSaga } from '@store/features/jobApplicants/sagas';

export default function* rootSaga() {
  yield all([
    loginUserProfileSaga(),
    loginUserFromSessionSaga(),
    jobApplicantsSaga(),
  ]);
}
