import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@store/rootReducer';
import SidebarPresentation from '@components/Sidebar/SidebarPresentation.tsx';

const mapStateToProps = (state: RootState) => ({
  loginUserProfile: state.loginUserProfile,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SidebarContainer: React.FC<PropsFromRedux> = ({ loginUserProfile }) => {
  return <SidebarPresentation loginUserProfile={loginUserProfile?.profile} />;
};

export default connector(SidebarContainer);
