import React from 'react';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import './index.scss';

interface PageSearchProps {
  placeholder?: string;
  onSearch?: () => void;
  onFilter?: () => void;
}

const PageSearch: React.FC<PageSearchProps> = ({
  placeholder = 'Search...',
  onSearch,
  onFilter,
}) => {
  return (
    <Box className="search-container" component="form">
      <InputBase
        className="search-input"
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
      />
      <IconButton
        type="button"
        className="search-icon-button"
        aria-label="search"
        onClick={onSearch}
      >
        <SearchIcon />
      </IconButton>
      <IconButton className="search-filter-button" onClick={onFilter}>
        <TuneIcon />
      </IconButton>
    </Box>
  );
};

export default PageSearch;
