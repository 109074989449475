import { call, put, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import ActionTypes, {
  fetchLoginUserProfileFailure,
  fetchLoginUserProfileSuccess,
} from './actions';

interface LoginPayload {
  email: string;
  password: string;
}

interface UserApiResponseType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

function* fetchUserProfileSaga(action: {
  type: string;
  payload: LoginPayload;
}) {
  try {
    const { email, password } = action.payload;
    const response: AxiosResponse<UserApiResponseType> = yield call(
      axios.post,
      '/api/login',
      { email, password },
    );
    yield put(fetchLoginUserProfileSuccess(response.data));
  } catch (error: any) {
    yield put(fetchLoginUserProfileFailure(error.response.data));
  }
}

// Watch for FETCH_USER_PROFILE_REQUEST action and call fetchUserProfileSaga
export function* loginUserProfileSaga() {
  yield takeLatest(
    ActionTypes.FETCH_LOGIN_USER_PROFILE_REQUEST,
    fetchUserProfileSaga,
  );
}

function* fetchLoginUserProfileFromSessionSaga() {
  try {
    setTimeout(() => {}, 10000);
    const response: AxiosResponse<UserApiResponseType> = yield call(
      axios.get,
      '/api/me',
    );
    yield put(fetchLoginUserProfileSuccess(response.data));
  } catch (error: any) {
    yield put(fetchLoginUserProfileFailure(error.response.data));
  }
}

// Watch for FETCH_USER_PROFILE_FROM_SESSION_REQUEST action and call fetchUserProfileFromSessionSaga
export function* loginUserFromSessionSaga() {
  yield takeLatest(
    ActionTypes.FETCH_LOGIN_USER_PROFILE_FROM_SESSION_REQUEST,
    fetchLoginUserProfileFromSessionSaga,
  );
}
