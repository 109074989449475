import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import './index.scss';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const Popup: React.FC<PopupProps> = ({ open, onClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default Popup;
