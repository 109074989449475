import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Container,
  Grid,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import PopupLayout from '@components/Common/PopupLayout';
import './index.scss';
import {
  jobApplicationTracking,
  jobApplicationStatusUpdate,
  jobApplicationNoteSave,
} from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import { SelectChangeEvent } from '@mui/material/Select';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';
import PopupHeader from '@components/Common/PopupHeader';

interface JobApplicationTrackingPopupProps {
  id: string;
  onClose: () => void;
}

interface JobApplication {
  _id: string;
  status: string;
  jobSeeker: {
    personalDetails: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      city: string;
      state: string;
      country: string;
    };
    jobPreferences: {
      location: string;
      noticePeriod: string;
      jobType: string[];
      preferredRoles: string[];
    };
    resume: string;
  };
  job: {
    title: string;
    company: {
      companyName: string;
    };
  };
  history: Array<{
    status: string;
    date: string;
    changedBy: string;
  }>;
  notes: Array<{
    hrId: string;
    note: string;
    date: string;
  }>;
}

const getClassName = (state: string) => {
  switch (state) {
    case 'In Review':
      return 'in-review';
    case 'Hired':
      return 'hired';
    case 'Rejected':
      return 'rejected';
    case 'Declined':
      return 'declined';
    default:
      return '';
  }
};

const JobApplicationTrackingPopup: React.FC<
  JobApplicationTrackingPopupProps
> = ({ id, onClose }) => {
  const [application, setApplication] = useState<JobApplication | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [newStatus, setNewStatus] = useState<string>('');
  const [newNote, setNewNote] = useState<string>('');

  const fetchData = useCallback(async (jobApplicationId: string) => {
    setLoading(true);
    setError(null);
    try {
      const query = jobApplicationTracking(jobApplicationId);
      const { data } = await fetchGraphQLApiData(query);
      const { getJobApplicationById } = data;
      setApplication(getJobApplicationById);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(id);
  }, [id, fetchData]);

  const handleStatusChange = (event: SelectChangeEvent) => {
    setNewStatus(event.target.value as string);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNote(event.target.value);
  };

  const handleSaveStatus = async () => {
    try {
      const query = jobApplicationStatusUpdate(id, newStatus);
      const { data } = await fetchGraphQLApiData(query);
      const { updateJobApplicationById } = data;
      setApplication(updateJobApplicationById);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }

    setNewStatus('');
  };

  const handleSaveNote = async () => {
    try {
      const query = jobApplicationNoteSave(id, newNote);
      const { data } = await fetchGraphQLApiData(query);
      const { updateJobApplicationById } = data;
      setApplication(updateJobApplicationById);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
    setNewNote('');
  };

  return (
    <PopupLayout className="job-application-tracking-popup-layout-container">
      <Container maxWidth="xl" className="job-application-tracking-container">
        <PopupHeader title="Job Application Tracking" onClose={onClose} />

        {loading && <Loader />}
        {error && <ErrorMessage message={error} />}

        {application && (
          <Grid container spacing={3}>
            {/* Timeline and Notes Section */}
            <Grid item xs={12} md={6}>
              <Card className="timeline-card">
                <CardContent>
                  <Typography variant="subtitle1" className="card-header">
                    Application History
                  </Typography>
                  <Timeline position="alternate-reverse">
                    {application.history.map((entry, index) => (
                      <TimelineItem key={index}>
                        <TimelineSeparator>
                          <TimelineDot className={getClassName(entry.status)} />
                          {index < application.history.length - 1 && (
                            <TimelineConnector />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="body2" color="textPrimary">
                            {entry.status}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {entry.date} - Changed by {entry.changedBy}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </CardContent>
              </Card>

              <Card className="notes-card">
                <CardContent>
                  <Typography variant="subtitle1" className="card-header">
                    Notes
                  </Typography>
                  <List>
                    {application.notes.map((note, index) => (
                      <ListItem
                        key={index}
                        className={`note-item ${index % 2 === 0 ? 'even' : 'odd'}`}
                      >
                        <ListItemText
                          primary={note.note}
                          secondary={`HR ID: ${note.hrId} - ${new Date(note.date).toLocaleDateString()}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            {/* Status and Note Input Section */}
            <Grid item xs={12} md={6}>
              <Card className="status-card">
                <CardContent>
                  <Typography variant="subtitle1" className="card-header">
                    Update Status
                  </Typography>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="form-control"
                  >
                    <InputLabel>Application Status</InputLabel>
                    <Select
                      value={newStatus}
                      label="Application Status"
                      onChange={handleStatusChange}
                      className="select-field"
                    >
                      <MenuItem value="In Review">In Review</MenuItem>
                      <MenuItem value="Interview Scheduled">
                        Interview Scheduled
                      </MenuItem>
                      <MenuItem value="Offered">Offered</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    className="save-button"
                    onClick={handleSaveStatus}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Save Status
                  </Button>
                </CardContent>
              </Card>

              <Card className="note-card">
                <CardContent>
                  <Typography variant="subtitle1" className="card-header">
                    Add Note
                  </Typography>
                  <TextField
                    className="text-field"
                    label="Add a note"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={newNote}
                    onChange={handleNoteChange}
                    margin="normal"
                  />
                  <Button
                    className="save-button"
                    onClick={handleSaveNote}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Save Note
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </PopupLayout>
  );
};

export default JobApplicationTrackingPopup;
