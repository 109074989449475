import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Box,
  Container,
  createTheme,
  Grid,
  Paper,
  ThemeProvider,
} from '@mui/material';
import Sidebar from '@components/Sidebar';
import JobApplicants from '../JobApplicants';
import HR from '../HR';
import NotFound from '@components/NotFound';
import Loader from '@components/Common/Loader';
import Authentication from '../AuthenticationForm';
import Jobs from '@components/Jobs';
import './index.scss';
import Companies from '@components/Companies';
import Users from '@components/Users';
import Dashboard from '@components/Dashboard';

interface LayoutProps {
  loginUserProfile: any;
}

const mintlyTheme = createTheme({
  palette: {
    primary: {
      main: '#5874BD',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeightMedium: 400,
  },
  shape: {
    borderRadius: 24,
  },
});

const LayoutPresentation: React.FC<LayoutProps> = ({ loginUserProfile }) => {
  const isLoading = loginUserProfile?.status === 'loading';
  const isAuthenticationNeeded = loginUserProfile?.status !== 'succeeded';

  return (
    <Router>
      <ThemeProvider theme={mintlyTheme}>
        <Container maxWidth={false} disableGutters className="container">
          {isAuthenticationNeeded && (
            <Box className="authentication-form-box">
              <Paper className="authentication-form-paper">
                {isLoading && <Loader />}
                {(loginUserProfile?.status === 'idle' ||
                  loginUserProfile?.status === 'failed') && <Authentication />}
              </Paper>
            </Box>
          )}
          {!isAuthenticationNeeded && (
            <Grid container spacing={2} className="grid">
              <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2}>
                <Paper className="sidebar-paper">
                  <Sidebar />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9.5} xl={10}>
                <Paper className="main-paper">
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/companies" element={<Companies />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/hr" element={<HR />} />
                    <Route path="/job-applicants" element={<JobApplicants />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </ThemeProvider>
    </Router>
  );
};

export default LayoutPresentation;
