import { combineReducers } from 'redux';
import loginUserProfileReducer, {
  LoginUserProfileState,
} from '@store/features/loginUserProfile/reducers';
import jobApplicantsReducer, {
  JobApplicantState,
} from '@store/features/jobApplicants/reducers';

export interface RootState {
  loginUserProfile: LoginUserProfileState;
  jobApplicants: JobApplicantState;
}

const rootReducer = combineReducers({
  loginUserProfile: loginUserProfileReducer,
  jobApplicants: jobApplicantsReducer,
});

export default rootReducer;
