import React, { useState, useCallback } from 'react';

interface DragDropFileProps {
  onFileDrop: (files: File) => void;
  file: File | '';
}

const FileUpload = ({ onFileDrop, file }: DragDropFileProps) => {
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState('');

  const supportedFileTypes = ['image/png', 'image/jpeg'];

  const maxFileSize = 2 * 1024 * 1024;

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFiles = (files: FileList) => {
    if (files.length > 1) {
      setError('Only one file can be uploaded.');
      return false;
    }

    const file = files[0];
    if (!supportedFileTypes.includes(file.type)) {
      setError('Unsupported file type. Please upload an image.');
      return false;
    }

    if (file.size > maxFileSize) {
      setError('File size should be less than 2mb');
      return false;
    }

    setError('');
    return true;
  };

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);

      const files = e.dataTransfer.files;
      if (files && files.length > 0) {
        if (validateFiles(files)) {
          onFileDrop(files[0]);
        }
      }
    },
    [onFileDrop],
  );

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      if (validateFiles(files)) {
        onFileDrop(files[0]);
      }
    }
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        border: dragging ? '2px dashed #4caf50' : '2px dashed #ccc',
        borderRadius: '5px',
        width: '100%',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: dragging ? '#f0fff0' : '#f9f9f9',
        color: dragging ? '#4caf50' : '#666',
        transition: 'all 0.3s ease',
      }}
    >
      <span
        style={{
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {dragging
          ? 'Drop the icon file here'
          : 'Drag and drop the icon file here or click to upload'}
      </span>

      <div style={{ textAlign: 'center' }}>
        <label
          htmlFor="file-upload"
          style={{
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: '#4caf50',
            color: '#fff',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Choose File
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
        />
      </div>
      {file !== '' && (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span>{file.name}</span>
          <span>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
        </div>
      )}
      {error && (
        <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
