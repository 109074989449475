import { Box, Typography } from '@mui/material';
import './index.scss';

const getClassName = (state: string) => {
  switch (state) {
    case 'In Review':
      return 'in-review';
    case 'Hired':
      return 'hired';
    case 'Rejected':
      return 'rejected';
    case 'Declined':
      return 'declined';
    default:
      return '';
  }
};

const JobApplicantStatus = (props: { status: string }) => {
  return (
    <Box className="job-applicant-status">
      <Box className={`status-dot ${getClassName(props.status)}`} />
      <Typography variant="caption" className="status-title">
        {props.status}
      </Typography>
    </Box>
  );
};

export default JobApplicantStatus;
