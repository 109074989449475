import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

interface PageHeaderProps {
  title: string;
  onClose: () => void;
}

const PopupHeader: React.FC<PageHeaderProps> = ({ title, onClose }) => {
  return (
    <Box className="popup-header-container">
      <Typography variant="h1" className="header">
        {title}
      </Typography>
      <IconButton
        className="close-button"
        onClick={onClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default PopupHeader;
