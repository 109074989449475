import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Typography,
  Autocomplete,
  Grid,
  Container,
} from '@mui/material';
import PopupLayout from '@components/Common/PopupLayout';
import './index.scss';
import { companyById, createUpdateStatusQuery } from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import FileUpload from '@components/Common/FileUpload/FileUpload';
import { TextEditor } from '@components/Common/TextEditor/TextEditor';
import { SelectChangeEvent } from '@mui/material/Select';
import PopupHeader from '@components/Common/PopupHeader';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';

const locations = {
  country: ['India', 'USA', 'Canada'], // Example countries
  city: ['Mumbai', 'Delhi', 'Bengaluru'], // Example cities
  zipCode: ['400001', '110001', '560001'], // Example ZIP codes
};

interface FormData {
  fullName: string;
  companyEmail: string;
  companyPhone: string;
  companyName: string;
  website: string;
  facebookURL: string;
  instagramURL: string;
  linkedInURL: string;
  location: string;
  description: string;
  logo: File | '';
  country: string;
  city: string;
  zipCode: string;
}

interface CompanyApprovalPopupProps {
  companyId: string;
  onClose: () => void;
}

const CompanyDetailsPopup: React.FC<CompanyApprovalPopupProps> = ({
  companyId,
  onClose,
}) => {
  const [company, setCompany] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);  
  const [newStatus, setNewStatus] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    companyEmail: '',
    companyPhone: '',
    companyName: '',
    website: '',
    facebookURL: '',
    instagramURL: '',
    linkedInURL: '',
    location: '',
    description: '',
    logo: '',
    country: '',
    city: '',
    zipCode: '',
  });

  const fetchData = useCallback(async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      const query = companyById(id);
      const { data } = await fetchGraphQLApiData(query);
      const { getCompanyById } = data;
      setCompany(getCompanyById);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(companyId);
  }, [companyId, fetchData]);

  useEffect(() => {
    if (company) {
      setFormData({
        fullName: company.fullName || '',
        companyEmail: company.companyEmail || '',
        companyPhone: company.companyPhone || '',
        companyName: company.companyName || '',
        website: company.website || '',
        facebookURL: company.facebookURL || '',
        instagramURL: company.instagramURL || '',
        linkedInURL: company.linkedInURL || '',
        location: `${company.companyDetails.city}, ${company.companyDetails.country}`,
        description: company.about || '',
        logo: company.logo || '',
        country: company.companyDetails.country || '',
        city: company.companyDetails.city || '',
        zipCode: company.companyDetails.zipCode || '',
      });
      setNewStatus(company.status || '');
    }
  }, [company]);

  const validateForm = () => {
    let tempErrors: { [key: string]: string } = {};
    if (!formData.fullName) tempErrors.fullName = 'Full Name is required';
    if (!formData.companyEmail || !/\S+@\S+\.\S+/.test(formData.companyEmail)) {
      tempErrors.companyEmail = 'Valid email is required';
    }
    if (!formData.companyPhone || !/^\d+$/.test(formData.companyPhone)) {
      tempErrors.companyPhone = 'Valid phone number is required';
    }
    if (!formData.companyName) tempErrors.companyName = 'Company Name is required';
    if (!formData.website || !/^https?:\/\/\S+$/.test(formData.website)) {
      tempErrors.website = 'Valid URL is required';
    }
    if (!formData.country) tempErrors.country = 'Country is required';
    if (!formData.city) tempErrors.city = 'City is required';
    if (!formData.zipCode || !/^\d+$/.test(formData.zipCode)) {
      tempErrors.zipCode = 'Valid ZIP code is required';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onStatusChange = (event: SelectChangeEvent) => {
    setNewStatus(event.target.value as string);
  };

  const updateCompanyGraphQL = async () => {
    setLoading(true);
    const updateQuery = createUpdateStatusQuery(companyId, newStatus);
    // {
    //   companyName: formData.companyName,
    //   companyEmail: formData.companyEmail,
    //   companyPhone: formData.companyPhone,
    //   website: formData.website,
    //   facebookURL: formData.facebookURL,
    //   instagramURL: formData.instagramURL,
    //   linkedInURL: formData.linkedInURL,
    //   about: formData.description,
    //   companyDetails: {
    //     country: formData.country,
    //     city: formData.city,
    //     zipCode: formData.zipCode,
    //   },
    //   logo: formData.logo,
    //   status: newStatus,
    // }

    try {
      await fetchGraphQLApiData(updateQuery);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (data: File | '') => {
    setFormData((prev) => ({ ...prev, logo: data }));
  };

  const setTextEditorValue = (value: string) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      updateCompanyGraphQL();
    }
  };

  const ApprovalStatus = [
    { value: 'REJECTED', label: 'Rejected' },
    { value: 'APPROVED', label: 'Approved' },
    { value: 'ON HOLD', label: 'On Hold' },
  ];

  return (
    <PopupLayout className="company-details-popup-layout-container">
      <Container maxWidth="xl" className="company-details-container">
        <PopupHeader title="Company Approval" onClose={onClose} />

        {loading && <Loader />}
        {error && <ErrorMessage message={error} />}

        {company && 
          <Grid container spacing={2}>
            {/* Status Update Section */}
            <Grid item xs={12}>
              <Card className="status-card">
                <CardContent>
                  <Typography variant="subtitle1" className="card-header">
                    Update Status
                  </Typography>
                  <FormControl 
                    fullWidth
                    variant="outlined"
                    className="form-control"
                  >
                    <InputLabel>Company Approval Status</InputLabel>
                    <Select 
                      label="Company Approval Status" 
                      value={newStatus} 
                      onChange={onStatusChange} 
                      className="select-field"
                    >
                      {ApprovalStatus.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>              
                  </FormControl>              
                  <Button
                    className="save-button"
                    onClick={updateCompanyGraphQL}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Update Status
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Form Fields */}
            {[
              { label: 'Full Name', name: 'fullName', type: 'text', required: true },
              { label: 'Company Email', name: 'companyEmail', type: 'email', required: true },
              { label: 'Company Phone', name: 'companyPhone', type: 'tel', required: true },
              { label: 'Company Name', name: 'companyName', type: 'text', required: true },
              { label: 'Website', name: 'website', type: 'url', required: true },
              { label: 'Facebook', name: 'facebookURL', type: 'url' },
              { label: 'Instagram', name: 'instagramURL', type: 'url' },
              { label: 'LinkedIn', name: 'linkedInURL', type: 'url' },            
            ].map((field, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Typography variant="subtitle1" align="left" className="card-header">
                  {field.label}{field.required && '*'}
                </Typography>
                <TextField
                  fullWidth
                  name={field.name}
                  value={(formData as any)[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]}                  
                  className="text-field"
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left" className="card-header">
                Description*
              </Typography>
              <TextEditor value={formData.description} setValue={setTextEditorValue} />
            </Grid>

            {[
              { label: 'Country', name: 'country', type: 'text', required: true },
              { label: 'City', name: 'city', type: 'text', required: true },
              { label: 'ZIP Code', name: 'zipCode', type: 'text', required: true }
            ].map((field, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Typography variant="subtitle1" align="left" className="card-header">
                  {field.label}{field.required && '*'}
                </Typography>
                <Autocomplete
                  options={locations[field.name]}
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={formData[field.name as keyof FormData]}
                  onInputChange={(e, newValue) => setFormData((prev) => ({ ...prev, [field.name]: newValue }))} // Update the correct field
                  freeSolo              
                  className="text-field"
                />
              </Grid>
            ))}

            <Grid item xs={12} sm={12} md={12}>
              <FileUpload onFileDrop={handleFileUpload} file={formData.logo} />
            </Grid>

            <Grid item xs={12}>
              <Button
                className="save-button"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Update Details
              </Button>
            </Grid>
          </Grid>
        }
      </Container>
    </PopupLayout>
  );
};

export default CompanyDetailsPopup;
