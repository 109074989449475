import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePath } from '@components/Common/Utils/Routes';
import NavigationItems from '@components/Common/Utils/NavigationItems';
import SidebarLogo from '@components/Common/SidebarLogo';
import './index.scss';

interface UserProfile {
  id: number;
  personalDetails: {
    firstName: string;
    lastName: string;
    email: string;
  };
  role: string;
}

interface SidebarProps {
  loginUserProfile: UserProfile;
}

const SidebarPresentation: React.FC<SidebarProps> = ({ loginUserProfile }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const updateSelection = (selection: string) => {
    navigate(routePath(selection));
    setOpen(false);
  };

  const isRoutePathMatch = (currentRoutePath: string, actualRoutePath: string) => {
    return currentRoutePath.split('/')[1] === actualRoutePath.split('/')[1];
  };

  const toggleDrawer = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const drawerContent = (
    <Box className="sidebar-container">
      <SidebarLogo />
      <Box className="nav-container">
        {NavigationItems.map(({ id, icon, label, path }) => (
          <Button
            key={id}
            className={`nav-button ${isRoutePathMatch(location.pathname, path) ? 'selected' : ''}`}
            onClick={() => updateSelection(label)}
          >
            <Box className="icon">{icon}</Box>
            <Typography className="text">{label}</Typography>
          </Button>
        ))}
      </Box>
      <Box className="user-data-container">
        <Box className="avatar-container">
          <Avatar alt="User Avatar" className="avatar" />
        </Box>
        <Box className="profile-data-container">
          <Typography className="name">
            {`${loginUserProfile.personalDetails.firstName} ${loginUserProfile.personalDetails.lastName}`}
          </Typography>
          <Typography className="role">{loginUserProfile.role}</Typography>
        </Box>
        <Box className="logout-button-container">
          <Button className="logout-button">
            <LogoutIcon className="icon" />
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {/* Mobile and tablet view */}
      <Box className="responsive-sidebar">
        <IconButton className="menu-button" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onOpen={toggleDrawer}
          onClose={toggleDrawer}
        >
          {drawerContent}
        </SwipeableDrawer>
        <SidebarLogo />
      </Box>
      {/* Desktop view */}
      <Box className="full-sidebar">{drawerContent}</Box>
    </>
  );
};

export default SidebarPresentation;
