import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NotificationIcon } from '@components/Common/NotificationIcon/NotificationIcon';
import './index.scss';

interface PageHeaderProps {
  title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <Box className="header-container">
      <Typography variant="h1" className="header">
        {title}
      </Typography>
      <Typography className="notification-container">
        <NotificationIcon live color="#00A2FA" />
      </Typography>
    </Box>
  );
};

export default PageHeader;
