import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  ContentEditableEvent,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';

interface TextEditorProps {
  value: string;
  setValue: (value: string) => void;
}

export const TextEditor = (props: TextEditorProps) => {
  const { value, setValue } = props;

  function onChange(e: ContentEditableEvent) {
    setValue(e.target.value);
  }

  return (
    <EditorProvider>
      <Editor
        containerProps={{
          style: {
            minHeight: '20vh',
            height: 'auto',
          },
        }}
        value={value}
        onChange={onChange}
      >
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnBulletList />
          <BtnNumberedList />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};
