const ActionTypes = {
  FETCH_LOGIN_USER_PROFILE_FROM_SESSION_REQUEST:
    'loginUserProfile/fetchUserProfileFromSessionRequest',
  FETCH_LOGIN_USER_PROFILE_REQUEST: 'loginUserProfile/fetchUserProfileRequest',
  FETCH_LOGIN_USER_PROFILE_SUCCESS: 'loginUserProfile/fetchUserProfileSuccess',
  FETCH_LOGIN_USER_PROFILE_FAILURE: 'loginUserProfile/fetchUserProfileFailure',
};

export const fetchLoginUserProfileFromSessionRequest = () => ({
  type: ActionTypes.FETCH_LOGIN_USER_PROFILE_FROM_SESSION_REQUEST,
});

export const fetchLoginUserProfileRequest = (payload: any) => ({
  type: ActionTypes.FETCH_LOGIN_USER_PROFILE_REQUEST,
  payload,
});

export const fetchLoginUserProfileSuccess = (payload: any) => ({
  type: ActionTypes.FETCH_LOGIN_USER_PROFILE_SUCCESS,
  payload,
});

export const fetchLoginUserProfileFailure = (error: string) => ({
  type: ActionTypes.FETCH_LOGIN_USER_PROFILE_FAILURE,
  payload: error,
});

export default ActionTypes;
