import React from 'react';
import { Box, Typography } from '@mui/material';
import './index.scss';

const NotFound: React.FC = () => {
  return (
    <Box className="not-found-box">
      <Box>
        <Typography variant="h2">404 Page Not Found</Typography>
        <Typography variant="body1">
          The page you are looking for does not exist.
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
