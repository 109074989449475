import PageLayout from '@components/Common/PageLayout';
import PageHeader from '@components/Common/PageHeader';

const Dashboard = () => {
  return (
    <PageLayout className="dashboard-container">
      <PageHeader title="Dashboard" />
      // TODO: Graphs for the following scenarios: (Filter: Month, country, city)
      // 1. Number of new registered job seekers (Monthly)
      // 2. Number of job applications (Monthly)
      // 3. Application status based graph (Approved/Rejected count) (Monthly)
      // 4. Number of new companies registered (Monthly)
      // 5. Number of new jobs posted (Monthly)
      // 6. Trending jobs (Monthly) (Max number of job applications)
    </PageLayout>
  );
};

export default Dashboard;
