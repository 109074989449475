import React, { useState } from 'react';
import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import './index.scss';

interface AuthenticationProps {
  onSubmit: (email: string, password: string) => void;
}

const AuthenticationFormPresentation: React.FC<AuthenticationProps> = ({
  onSubmit,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <form onSubmit={handleSubmit} className='authentication-form-container'>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Login
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            className="authentication-form-text-field"
            type="email"
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="authentication-form-text-field"
            type="password"
            fullWidth
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button className="authentication-form-sign-in-button" type="submit" variant="contained" color="primary" fullWidth>
            Sign In
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center">
            Don't have an account? <Link href="#">Sign Up</Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthenticationFormPresentation;
