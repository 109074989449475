import { useEffect, useCallback, useState } from 'react';
import { Container } from '@mui/material';
import PopupLayout from '@components/Common/PopupLayout';
import './index.scss';
import PopupHeader from '@components/Common/PopupHeader';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';

export interface HRFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  jobType: string;
  location: string;
  noticePeriod: string;
  preferredRoles: string;
  id: string;
  resume: string;
}

interface HRTrackingPopupProps {
  hrId: string;
  onClose: () => void;
}

const JobDetailsPopup: React.FC<HRTrackingPopupProps> = ({ hrId, onClose }) => {
  const [hr, setHr] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  // const [formData, setFormData] = useState<JobFormData>({
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   phone: '',
  //   address: '',
  //   city: '',
  //   state: '',
  //   zipcode: '',
  //   country: '',
  //   jobType: '',
  //   location: '',
  //   noticePeriod: '',
  //   preferredRoles: '',
  //   id: jobId,
  //   resume: '',
  // });

  const fetchData = useCallback(async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      // const query = getUserById(id);
      // const { data } = await fetchGraphQLApiData(query);
      // setJob(data.getJobSeekerById);
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(hrId);
  }, [hrId, fetchData]);

  useEffect(() => {
    if (hr) {
      // setFormData({
      //   firstName: job.personalDetails.firstName || '',
      //   lastName: job.personalDetails.lastName || '',
      //   email: job.personalDetails.email || '',
      //   phone: job.personalDetails.phone || '',
      //   address: job.personalDetails.address || '',
      //   city: job.personalDetails.city || '',
      //   state: job.personalDetails.state || '',
      //   zipcode: job.personalDetails.zipCode || '',
      //   country: job.personalDetails.country || '',
      //   jobType: job.jobPreferences.jobType.join(', ') || '',
      //   location: job.jobPreferences.location || '',
      //   noticePeriod: job.jobPreferences.noticePeriod || '',
      //   preferredRoles: job.jobPreferences.preferredRoles.join(', ') || '',
      //   id: jobId,
      //   resume: job.resume || '',
      // });
    }
  }, [hr, hrId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const query = '';//updateJobSeeker(formData);
    
    try {
      await fetchGraphQLApiData(query);      
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopupLayout className="hr-tracking-popup-layout-container">
      <Container maxWidth="xl" className="hr-tracking-container">
        <PopupHeader title="HR Tracking" onClose={onClose} />

        {loading && <Loader />}
        {error && <ErrorMessage message={error} />}

        {hr && 
          <div>TODO</div>
        }
      </Container>
    </PopupLayout>
  );
};

export default JobDetailsPopup;
