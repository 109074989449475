import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import JobApplicantStatus from '@components/Common/JobApplicantStatus';
import MintlyTable from '@components/Common/Table';
import PageHeader from '@components/Common/PageHeader';
import PageSearch from '@components/Common/PageSearch';
import PageLayout from '@components/Common/PageLayout';
import Popup from '@components/Common/Popup';
import JobApplicationTrackingPopup from '@components/JobApplicationTrackingPopup';
import Loader from '@components/Common/Loader';
import ErrorMessage from '@components/Common/ErrorMessage';
import { jobApplicantsList } from '@constants/graphQLQueries';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import './index.scss';

interface JobApplicant {
  id: string;
  name: string;
  designation: string;
  companyName: string;
  status: string;
}

const rowsPerPage = 10;

const JobApplicants: React.FC = () => {
  const [page, setPage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedJobApplicantId, setSelectedJobApplicantId] = useState('');
  const [data, setData] = useState<JobApplicant[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const fetchData = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const query = jobApplicantsList(page, rowsPerPage);
      const { data } = await fetchGraphQLApiData(query);
      const { getAllJobApplications } = data;

      if (
        getAllJobApplications.content &&
        getAllJobApplications.totalElements
      ) {
        const jobApplicants: JobApplicant[] = getAllJobApplications.content.map(
          (item: any) => ({
            id: item._id,
            name: `${item.jobSeeker.personalDetails.firstName} ${item.jobSeeker.personalDetails.lastName}`,
            designation: item.job.title,
            companyName: item.job.company.companyName,
            status: item.status,
          }),
        );

        setData(jobApplicants);
        setTotalElements(getAllJobApplications.totalElements);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('An unknown error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [fetchData, page]);

  const renderContent = () => {
    if (loading) {
      return (
        <Loader />
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    return (
      <MintlyTable
        rows={data}
        pagination={{
          enabled: true,
          page,
          rowsPerPage,
          totalNumberOfRows: totalElements,
          handleChangePage,
        }}
        selectors={['name', 'designation', 'companyName', 'status', 'expand']}
        tableHeadRow={{
          name: 'Name',
          designation: 'Designation',
          companyName: 'Company Name',
          status: 'Status',
          expand: '',
        }}
        tableBodyRow={{
          name: (row: JobApplicant) => row.name,
          designation: (row: JobApplicant) => row.designation,
          companyName: (row: JobApplicant) => row.companyName,
          status: (row: JobApplicant) => (
            <JobApplicantStatus status={row.status} />
          ),
          expand: (row: JobApplicant) => (
            <IconButton
              className="view-button"
              onClick={() => {
                setSelectedJobApplicantId(row.id);
                setPopupOpen(true);
              }}
            >
              View
            </IconButton>
          ),
        }}
      />
    );
  };

  return (
    <PageLayout className="job-applicants-container">
      <PageHeader title="Job Applicants" />
      <PageSearch placeholder="Search Job Applicants..." />
      {renderContent()}
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
        <JobApplicationTrackingPopup id={selectedJobApplicantId} onClose={() => setPopupOpen(false)} />
      </Popup>
    </PageLayout>
  );
};

export default JobApplicants;
