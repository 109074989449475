import React from 'react';
import Box from '@mui/material/Box';
import './index.scss';

interface PageLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  className = '',
}) => {
  return (
    <Box className={`popup-layout-container ${className}`}>{children}</Box>
  );
};

export default PageLayout;
