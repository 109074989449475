export const routePath = (page: string) => {
  switch (page) {
    case 'Dashboard':
      return '/';
    case 'Jobs':
      return '/jobs';
    case 'Companies':
      return '/companies';
    case 'Users':
      return '/users';
    case 'HR':
      return '/hr';
    case 'Applicants':
      return '/job-applicants';
    default:
      return '*';
  }
};
