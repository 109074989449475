import React, { useCallback, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import './index.scss';
import PageHeader from '@components/Common/PageHeader';
import PageSearch from '@components/Common/PageSearch';
import PageLayout from '@components/Common/PageLayout';
import Popup from '@components/Common/Popup';
import HRTrackingPopup from '@components/HRTrackingPopup';
import MintlyTable from '@components/Common/Table';
import { fetchGraphQLApiData } from '@helpers/graphQLApi';
import ErrorMessage from '@components/Common/ErrorMessage';
import Loader from '@components/Common/Loader';

function createData(name: string, jobsHandled: number) {
  return { name, jobsHandled };
}

const rows = [
  createData('John Doe', 150),
  createData('Jane Smith', 200),
  createData('Michael Johnson', 180),
  createData('Emily Davis', 170),
  createData('David Wilson', 160),
  createData('Sophia Martinez', 190),
  createData('James Brown', 175),
  createData('Olivia Taylor', 165),
  createData('Liam Anderson', 155),
  createData('Isabella Thomas', 210),
  createData('William White', 140),
  createData('Ava Harris', 185),
  createData('Ethan Martin', 145),
  createData('Mia Thompson', 195),
  createData('Alexander Garcia', 130),
];

const rowsPerPage = 10;

interface HRList {
  id: string;
  name: string;
  designation: string;
  companyName: string;
  status: string;
}

const HR = () => {
  const [page, setPage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState([]);
  const [selectedHrId, setSelectedHrId] = useState(null);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const fetchData = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const query = '';
      // const { data } = await fetchGraphQLApiData(query);
      // const { getAllCompanies } = data;

      // if (getAllCompanies.content && getAllCompanies.totalElements) {
      //   const hrList: HRList[] = getAllCompanies.content.map(
      //     (item: any) => ({
      //       id: item._id,
      //       address: {
      //         country: item.companyDetails?.country,
      //         city: item.companyDetails?.city ?? '',
      //         zipCode: item.companyDetails?.zipCode ?? '',
      //       },
      //       companyName: item.companyName,
      //       approvalStatus: item.status ?? '',
      //     }),
      //   );

      //   setData(hrList);
        // setTotalElements(getAllCompanies.totalElements);
      // } else {
      //   throw new Error('Unexpected response format');
      // }
    } catch (error) {
      console.error('An unknown error occurred while fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!popupOpen) {
      fetchData(page);
    }
  }, [fetchData, page]);

  const rowTotal = rows.length;
  const displayedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Loader />
      );
    }

    if (error) {
      return <ErrorMessage message={error} />;
    }

    return (
      <MintlyTable
        rows={displayedRows}
        pagination={{
          enabled: true,
          page: page,
          rowsPerPage,
          totalNumberOfRows: rowTotal,
          handleChangePage: handleChangePage,
        }}
        selectors={['name', 'jobsHandled', 'expand']}
        tableHeadRow={{        
          name: <>HR Name</>,
          jobsHandled: <>Jobs Handled</>,
          expand: <></>,          
        }}
        tableBodyRow={{          
          name: (row: any) => <>{row.name}</>,
          jobsHandled: (row: any) => <>{row.jobsHandled}</>,
          expand: (row: any) => (
            <IconButton
              className="view-button"
              onClick={() => {
                setSelectedHrId(row.id);
                setPopupOpen(true);
              }}
            >
              View
            </IconButton>
          ),          
        }}
      />
    );
  };

  return (
    <PageLayout className="hr-container">
      <PageHeader title="HR Listing" />
      <PageSearch placeholder="Search HRs..." />
      {renderContent()}
      <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
        <HRTrackingPopup hrId={selectedHrId} onClose={() => setPopupOpen(false)} />
      </Popup>
    </PageLayout>
  );
};

export default HR;
