import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import './NotificationIcon.scss';

type iconProps = {
  live: boolean;
  color?: string;
};

export const NotificationIcon = ({ live, color = '#5C37FF' }: iconProps) => {
  const [active, setActive] = useState(live);

  return (
    <IconButton className="button" onClick={() => setActive(!active)}>
      {active ? (
        <NotificationsActiveIcon sx={{ color: { color } }} />
      ) : (
        <NotificationsIcon sx={{ color: 'grey' }} />
      )}
    </IconButton>
  );
};
