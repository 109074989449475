interface JobApplicant {
  id: string;
  name: string;
  designation: string;
  companyName: string;
  status: string;
}

export enum ActionTypes {
  FETCH_JOB_APPLICANTS_REQUEST = 'jobApplicants/fetchRequest',
  FETCH_JOB_APPLICANTS_SUCCESS = 'jobApplicants/fetchSuccess',
  FETCH_JOB_APPLICANTS_FAILURE = 'jobApplicants/fetchFailure',
}

export interface FetchJobApplicantsRequestAction {
  type: typeof ActionTypes.FETCH_JOB_APPLICANTS_REQUEST;
}

export interface FetchJobApplicantsSuccessAction {
  type: typeof ActionTypes.FETCH_JOB_APPLICANTS_SUCCESS;
  payload: {
    data: JobApplicant[];
    totalElements: number;
  };
}

export interface FetchJobApplicantsFailureAction {
  type: typeof ActionTypes.FETCH_JOB_APPLICANTS_FAILURE;
  payload: string;
}

export const fetchJobApplicantsRequest =
  (): FetchJobApplicantsRequestAction => ({
    type: ActionTypes.FETCH_JOB_APPLICANTS_REQUEST,
  });

export const fetchJobApplicantsSuccess = (
  data: JobApplicant[],
  totalElements: number,
): FetchJobApplicantsSuccessAction => ({
  type: ActionTypes.FETCH_JOB_APPLICANTS_SUCCESS,
  payload: { data, totalElements },
});

export const fetchJobApplicantsFailure = (
  error: string,
): FetchJobApplicantsFailureAction => ({
  type: ActionTypes.FETCH_JOB_APPLICANTS_FAILURE,
  payload: error,
});

export default ActionTypes;
