import logo from '@assets/mintylLogo.png';
import { Box, Typography } from '@mui/material';
import './index.scss';

const SidebarLogo = () => {
  return (
    <Box className="sidebar-logo">
      <img className="image" src={logo} alt="mintly" />
      <Typography className="premium-text">PREMIUM</Typography>
    </Box>
  );
};

export default SidebarLogo;
